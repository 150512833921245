import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const isEqualEvents = (trackEvent, event) => {
  const eventProperties = Object.keys(trackEvent);
  return eventProperties.length && eventProperties.every(key => trackEvent[key] === event[key]);
};

const isObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

export const useWatchDataLayer = (trackEvent) => {
  if (typeof window === 'undefined' || !isObject(trackEvent) || !window.dataLayer) {
    return false;
  }

  const [haveEvent, setHaveEvent] = useState(false);

  const nativePush = useRef(window.dataLayer.push);

  const eventCallback = useCallback(() => {
    setHaveEvent(true);
  }, []);

  const watchPush = useCallback((eventToPush, ...restArg) => {
    nativePush.current.apply(window.dataLayer, [eventToPush, ...restArg]);

    if (isEqualEvents(trackEvent, eventToPush)) {
      eventCallback();
    }
  }, [nativePush]);

  useEffect(() => {
    window.dataLayer.push = watchPush;
  }, [watchPush]);

  return haveEvent;
};
