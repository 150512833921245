import defaultConfig from '@carwow/injection-default-config';

const shouldInject = (
  {
    bodyIndex,
  },
) => {
  const remainder = bodyIndex % 3;
  return bodyIndex !== 0 && remainder === 0;
};

const inlineAdsSpecsList = {
  id: 'inline-ads-specs-list',
  componentType: 'inlineAdSpecsList',
  condition: options => shouldInject(options),
  component: ({ componentCount: adCount }) => defaultConfig.getInlineAdConfig(adCount),
  resetCurrentItemTypeCurrentCounter: true,
};

export default [
  inlineAdsSpecsList,
];
